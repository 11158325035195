.appLayout-content {
  width: 100vm;
  height: 100vh;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.appLayout-right-box {
  background: #EFEEEE;
  transition: margin-left .2s;
}

.appLayout-right-body {
  background: white;
  width: 100%;
  height: calc(100vh - 112px);
  min-height: 586px;
  margin: 4px 0;
  overflow: hidden;
}

.applayout-slider {
  //position: fixed;
  //left: 0;
  overflow: auto;
  height: 100%;
}

.applayout-logo {
  width: 100%;
  padding: 16px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;

  > img {
    width: 70%;
  }
}

.slider-menu {
  > li {
    margin: 15px 0;
  }
}


.header-content {
  width: 100%;
  height: 56px;
  background: white;
  display: flex;
  justify-content: space-between;

  .header-left-box {
    height: 100%;
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 15px;
    color: #333;

    > img {
      height: 100%;
      margin-left: 50px;
    }

    .header-left-trigger {
      font-size: 18px;
      padding: 0 20px;
      box-sizing: border-box;
      transition: color 0.5s;
    }

    .header-left-trigger:hover {
      color: #FF4460;
    }
  }

  .header-right-box {
    height: 100%;
    display: flex;
    align-items: center;
    padding-top: 4px;
    position: relative;

    > span {
      width: 92px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #333;
      font-weight: 500;
      transition: font-size .3s;
    }

    > span:hover {
      color: #FF4460;
      font-size: 15px;
      font-weight: bold;
    }

    .header-right-line {
      width: 92px;
      height: 4px;
      border-radius: 2px;
      background: #FF4460;
      position: absolute;
      left: 0;
      bottom: 4px;
      transition: left .3s;
    }

    .header-right-check {
      font-size: 15px;
      font-weight: bold;
      color: #FF4460;
    }

  }
}

.header-menu-item:hover {
  color: #FF4460;
}


.fotter-content {
  width: 100%;
  height: 48px;
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;

  > div {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    > a {
      width: 100px;
      text-align: center;
      color: #666;
      transition: color .5s;
    }

    > a:hover {
      color: #FF4460;
    }

    .fotter-line {
      background: #666;
    }
  }
}

