.antd-pagination {
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
  font-size: 12px;

  .ant-select {
    font-size: 12px;
  }
}
.antd-table {
  overflow: auto;
  //width: 100%;
  flex: 1;

  .ant-table-thead > tr > th {
    padding: 0 5px;
    height: 42px;
  }

  .ant-table-tbody > tr > td {
    padding: 0 5px;
    height: 40px;
  }

  .ant-table-footer {
    padding: 0 16px!important;
  }
}

.themeColor {
  color: #ff4460!important;
}
.green{
  color: green!important;
}
.red{
  color: red!important;
}


.btn-edit{
  color: #1890FF!important;
  padding: 5px 10px;
}
.btn-del{
  color: #F3001B!important;
  padding: 5px 10px;
}
.btn-done{
  color: #46BD15!important;
  padding: 5px 10px;
}
.table-yes{
  color: #137228!important;
}
.table-no{
  color: #F79D13!important;
}
.table-disabled{
  color: #999!important;
  font-size: 12px;
}
.table-pic {
  width: 60px;
  height: 30px;
  margin: 0 2px;
  border-radius: 4px;
}

.bread-content {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
}

